<template>
    <div class="login-wrap">
        <div class="login-card">
            <transition name="fade">
                <component :is="active" :active.sync="active"></component>
            </transition>
        </div>
        <div class="login-bg">
            <img class="login-img" src="@/assets/login-img.svg" alt="">
        </div>
    </div>
</template>

<script>
import LoginBox from './components/login-box'
import RegisterBox from './components/register-box'
import ForgetBox from './components/forget-box'

export default {
    components: {
        LoginBox,
        RegisterBox,
        ForgetBox
    },
    data () {
        return {
            active: 'loginBox',
            reqStatus: 1
        }
    },
    watch: {
        $route (nval, oval) {
            if (this.$route.query && this.$route.query.active) {
                this.active = this.$route.query.active
            }
        }
    },
    created () {
        if (this.$route.query && this.$route.query.active) {
            this.active = this.$route.query.active
        }
    }
}
</script>

<style lang="scss" scoped>
.login-wrap {
    display: flex;
    flex-direction: row;
    height: $mainHeight;
    width: 100%;
    min-height: $mainHeight;
    overflow: hidden;
    .login-card{
        width: 54%;
        flex-grow: 1;
        flex-shrink: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 50px 190px 50px 50px;
        background: white;
        box-sizing: border-box;
    }
    .login-bg {
        height: 100%;
        width: 46%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        min-width: 600px;
        background: url(/images/login-bg.svg) center no-repeat;
        background-size: cover;
        background-position: center;
        background-color: $mainPrimary;
        padding: 50px;
        box-sizing: border-box;
        .login-img{
            width: 496px;
            height: 570px;
            z-index: 9;
        }
    }
}
.fade-enter-active {
    transition: opacity .5s
}
.fade-leave-active {
  transition: opacity .0s
}
.fade-enter, .fade-leave-active {
  opacity: 0
}
</style>
