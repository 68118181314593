<template>
    <div class="forget-box">
        <div class="header-wrap">
            <label>RESET PASSWORD</label>
            <div class="tips">Enter your email address and we'll send you an email with instructions to reset your password.</div>
        </div>
        <el-form ref="ruleForm"
            :model="ruleForm"
            :rules="rules"
            :disabled="loading">
            <el-form-item prop="email">
                <el-input
                    v-model="ruleForm.email"
                    placeholder="Email"
                    :disabled="haveEmail"
                    clearable>
                </el-input>
            </el-form-item>
            <el-form-item prop="password" v-if="haveEmail">
                <el-input
                    v-model="ruleForm.password"
                    placeholder="Password"
                    show-password
                    clearable>
                </el-input>
            </el-form-item>
            <el-form-item prop="againPassword" v-if="haveEmail">
                <el-input
                    v-model="ruleForm.againPassword"
                    placeholder="Enter the password again"
                    show-password
                    clearable>
                </el-input>
            </el-form-item>
        </el-form>
        <el-button
            class="login-btn"
            type="primary"
            :loading="loading"
            @click="doForget">
            RESET PASSWORD
        </el-button>
        <div class="account">
            Back to
            <el-link type="primary" :disabled="loading" @click="$emit('update:active', 'loginBox')">LOG IN</el-link>
        </div>
    </div>
</template>

<script>
    import { valideEmail } from '@/utils/pattern.js'
    export default {
        props: {
            active: String
        },
        data () {
            return {
                loading: false,
                remember: false,
                haveEmail: false,
                ruleForm: {
                    email: '',
                    password: '',
                    againPassword: ''
                },
                rules: {
                    email: [{ required: true, validator: valideEmail, trigger: 'change' }],
                    password: [{ required: true, message: 'Your password is required!', trigger: 'change' }],
                    againPassword: [{ required: true, message: 'Your password is required!', trigger: 'change' }]
                }
            }
        },
        watch: {
            $route (nval, oval) {
                if (this.$route.query && this.$route.query.email) {
                    this.haveEmail = !!(this.$route.query.email)
                    this.ruleForm.email = this.$route.query.email
                }
            }
        },
        created () {
            if (this.$route.query && this.$route.query.email) {
                this.haveEmail = !!(this.$route.query.email)
                this.ruleForm.email = this.$route.query.email
            }
        },
        methods: {
            doForget () {
                this.$refs.ruleForm &&
                this.$refs.ruleForm.validate((valid) => {
                    if (valid) {
                        this.loading = true
                        if (!this.haveEmail) {
                            this.reqSendEmail()
                        } else {
                            if (this.ruleForm.againPassword === this.ruleForm.password) {
                                this.reqResetPwd()
                            } else {
                                this.loading = false
                                this.$message.warning('The password does not match the confirmed password.')
                            }
                        }
                    }
                })
            },
            // 重置密码邮箱发邮件
            reqSendEmail () {
                this.$api.checkUserName({
                    username: this.ruleForm.email
                }).then((res) => {
                    if (res.code === 0) {
                        this.$message.success('The email has been sent, please check the email address modification.')
                        setTimeout(() => {
                            this.loading = false
                            this.$router.push({
                                path: '/login',
                                query: {
                                    active: 'loginBox'
                                }
                            })
                        }, 3000)
                    } else {
                        this.loading = false
                        this.$message.error(res.message)
                    }
                })
            },
            // 重置密码
            reqResetPwd () {
                this.$api.updatePassword({
                    rePassword: this.ruleForm.againPassword,
                    password: this.ruleForm.password,
                    username: this.ruleForm.email,
                    code: this.$route.query && this.$route.query.code
                }).then((res) => {
                    if (res.code === 0) {
                        setTimeout(() => {
                            this.loading = false
                            this.$router.push({
                                path: '/login',
                                query: {
                                    active: 'loginBox'
                                }
                            })
                        }, 3000)
                    } else {
                        this.loading = false
                        this.$message.error(res.message)
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
.forget-box{
    width: 400px;
    min-height: 400px;
    .header-wrap{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        label{
            font-size: 32px;
            font-family: PingFangSC, PingFangSC-Semibold;
            font-weight: 600;
            margin-bottom: 8px;
            height: 45px;
            line-height: 45px;
        }
        .tips{
            font-size: 14px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            margin: 0 8px;
            text-align: center;
            color: #000000;
            line-height: 20px;
            margin-bottom: 32px;
        }
    }
    .login-btn{
        width: 100%;
    }
    .btn-wrap{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 22px;
    }
    .label{
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #000000;
    }
    .account{
        margin: 14px 0;
        text-align: center;
    }
}
</style>
