import { render, staticRenderFns } from "./header.vue?vue&type=template&id=0adb16b9&scoped=true&"
var script = {}
import style0 from "./header.vue?vue&type=style&index=0&id=0adb16b9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0adb16b9",
  null
  
)

export default component.exports