<template>
    <div class="register-box">
        <rap-header></rap-header>
        <el-form ref="ruleForm"
            :model="ruleForm"
            :rules="rules">
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item prop="firstName">
                        <el-input
                            v-model="ruleForm.firstName"
                            placeholder="Fisrt name"
                            clearable>
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item prop="lastName">
                        <el-input
                            v-model="ruleForm.lastName"
                            placeholder="Last name"
                            clearable>
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-form-item prop="email">
                <el-input
                    v-model="ruleForm.email"
                    placeholder="Email"
                    clearable>
                </el-input>
            </el-form-item>
            <el-form-item prop="phone">
                <el-input
                    v-model="ruleForm.phone"
                    placeholder="Phone"
                    clearable>
                </el-input>
            </el-form-item>
            <el-form-item prop="password">
                <rap-pwd-input v-model="ruleForm.password"
                    placeholder="Password"
                    show-password
                    clearable>
                </rap-pwd-input>
            </el-form-item>
        </el-form>
        <div class="btn-wrap">
            <el-checkbox
                v-model="accept">
                <span class="label">I accept Terms and conditions</span>
            </el-checkbox>
        </div>
        <el-button
            class="login-btn"
            type="primary"
            :disabled="!accept"
            @click="doRegister">
            SIGN UP
        </el-button>
        <div class="account">
            Already have an account ?
            <el-link type="primary" @click="$emit('update:active', 'loginBox')">Sign In</el-link>
        </div>
    </div>
</template>

<script>
    import Header from './header'
    import RadPwdInput from './pwd-input.vue'
    import { valideEmail, validePhone } from '@/utils/pattern.js'
    export default {
        props: {
            active: String
        },
        components: {
            'rap-header': Header,
            'rap-pwd-input': RadPwdInput
        },
        data () {
            return {
                accept: false,
                ruleForm: {
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    password: ''
                },
                rules: {
                    firstName: [{ required: true, message: 'Your first name is required!', trigger: 'change' }],
                    lastName: [{ required: true, message: 'Your last Name is required!', trigger: 'change' }],
                    email: [{ required: true, validator: valideEmail, trigger: 'change' }],
                    phone: [{ required: true, validator: validePhone, trigger: 'change' }],
                    password: [{ required: true, message: 'Your password is required!', trigger: 'change' }]
                }
            }
        },
        methods: {
            doRegister () {
                this.$refs.ruleForm &&
                this.$refs.ruleForm.validate((valid) => {
                    if (valid) {
                        this.reqRegister()
                    }
                })
            },
            reqRegister () {
                this.$api.register({
                    first_name: this.ruleForm.firstName,
                    last_name: this.ruleForm.lastName,
                    username: this.ruleForm.email,
                    mobile: this.ruleForm.phone,
                    password: this.ruleForm.password,
                    source_type: '1'// 1.pc端, 2.web手机端
                }).then((res) => {
                    if (res.code === 0) {
                        this.reqLogin()
                    } else {
                        this.$message.error(res.message || '')
                    }
                })
            },
            // 请求登录
            reqLogin () {
                this.$api.login({
                    username: this.ruleForm.email,
                    password: this.ruleForm.password,
                    login_type: '1'// 1是web，2是app
                }).then((res) => {
                    if (res.code === 0) {
                        const data = res.data
                        // token
                        if (data.user_token) {
                            // 七天有效
                            if (this.remember) {
                                this.$cookie.setToken('USER_TOKEN', data.user_token, 7)
                                localStorage.setItem('REMEMBER_ACCOUNT', true)
                            } else {
                                this.$cookie.setToken('USER_TOKEN', data.user_token, 1)
                                localStorage.removeItem('REMEMBER_ACCOUNT')
                            }
                        }
                        // user_info
                        localStorage.setItem('USER_INFO', JSON.stringify(data))
                        localStorage.setItem('IS_LOGIN', true)
                        this.$store.commit('userCenter/UPDATE_USERINFO_TYPE', null)
                        this.$store.commit('userCenter/UPDATE_USER_SHOPIFYSTORE_TYPE', 0)
                        // 是否激活URL登录，跳转仪表盘|商品列表
                        this.$router.push({
                            path: '/link-account'
                        })
                    } else {
                        this.$message.error(res.message)
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
.register-box{
    width: 400px;
    min-height: 400px;
    .login-btn{
        width: 100%;
    }
    .btn-wrap{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 22px;
    }
    .label{
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #000000;
    }
    .account{
        margin: 14px 0;
        text-align: center;
    }
}
</style>
