<template>
    <div class="login-box">
        <rap-header></rap-header>
        <transition name="el-fade-in">
            <div v-if="reqStatus" :class="['alert-card', `status-${reqStatus}`]" >
                <template v-if="reqStatus===1">
                    <i class="el-icon-success"></i>
                    <div class="content">
                        Your account: {{ ruleForm.email }} activated successfully.
                    </div>
                </template>
                <template v-if="reqStatus===2">
                    <i class="el-icon-error"></i>
                    <div class="content">
                        Your account: {{ ruleForm.email }} activated failed.
                    </div>
                </template>
                <div class="close-btn" @click="reqStatus = 0">
                    <i class="el-icon-close"></i>
                </div>
            </div>
        </transition>
        <el-form ref="ruleForm"
            :model="ruleForm"
            :rules="rules">
            <el-form-item prop="email">
                <el-input
                    v-model="ruleForm.email"
                    placeholder="Email"
                    clearable>
                </el-input>
            </el-form-item>
            <el-form-item prop="password">
                <el-input
                    v-model="ruleForm.password"
                    placeholder="Password"
                    show-password
                    clearable>
                </el-input>
            </el-form-item>
        </el-form>
        <div class="btn-wrap">
            <el-checkbox
                v-model="remember">
                <span class="label">Remember me</span>
            </el-checkbox>
            <el-link @click="jump('forgetBox')">Forgot Password?</el-link>
        </div>
        <el-button
            class="login-btn"
            type="primary"
            @click="doLogin">
            LOG IN
        </el-button>
        <div class="account">
            Don't have an account?
            <el-link type="primary" @click="$emit('update:active', 'registerBox')">Sign up</el-link>
        </div>
    </div>
</template>

<script>
    import Header from './header'
    import { valideEmail } from '@/utils/pattern.js'
    export default {
        props: {
            active: String
        },
        components: {
            'rap-header': Header
        },
        data () {
            return {
                remember: false,
                ruleForm: {
                    email: '',
                    password: ''
                },
                rules: {
                    email: [{ required: true, validator: valideEmail, trigger: 'change' }],
                    password: [{ required: true, message: 'Please enter a password.', trigger: 'change' }]
                },
                active_source: '',
                reqStatus: 0
            }
        },
        created () {
            if (this.$route.query && this.$route.query.username && this.$route.query.active === 'loginBox') {
                this.ruleForm.email = this.$route.query.username
                this.reqActiveUser()
            }
            // 回车登录
            window.addEventListener('keydown', (e) => {
                if (e.keyCode === 13) {
                    this.doLogin()
                }
            })
        },
        methods: {
            // 切换内容
            jump (boxStr) {
                this.$emit('update:active', boxStr)
            },
            // 登录
            doLogin () {
                this.$refs.ruleForm &&
                this.$refs.ruleForm.validate((valid) => {
                    if (valid) {
                        this.reqLogin()
                    }
                })
            },
            // 请求登录
            reqLogin () {
                this.$api.login({
                    username: this.ruleForm.email,
                    password: this.ruleForm.password,
                    login_type: '1'// 1是web，2是app
                }).then((res) => {
                    if (res.code === 0) {
                        const data = res.data
                        // token
                        if (data.user_token) {
                            // 七天有效
                            if (this.remember) {
                                this.$cookie.setToken('USER_TOKEN', data.user_token, 7)
                                localStorage.setItem('REMEMBER_ACCOUNT', true)
                            } else {
                                this.$cookie.setToken('USER_TOKEN', data.user_token, 1)
                                localStorage.removeItem('REMEMBER_ACCOUNT')
                            }
                        }
                        // user_info
                        localStorage.setItem('USER_INFO', JSON.stringify(data))
                        localStorage.setItem('IS_LOGIN', true)
                        this.$store.commit('userCenter/UPDATE_USERINFO_TYPE', null)
                        this.$store.commit('userCenter/UPDATE_USER_SHOPIFYSTORE_TYPE', 0)
                        // 是否激活URL登录，跳转仪表盘|商品列表
                        let path = this.$route.query.redirect
                        if (!path) {
                            path = '/product/list'
                        }
                        this.$router.push({ path })
                    } else {
                        this.$message.error(res.message)
                    }
                })
            },
            // 激活用户
            reqActiveUser () {
                this.$api.updateUserStatus({
                    username: this.ruleForm.email || ''
                }).then(res => {
                    if (res.code === 0) {
                        this.reqStatus = 1
                    } else {
                        this.reqStatus = 2
                    }
                }).catch(() => {
                    this.reqStatus = 2
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
.login-box{
    width: 400px;
    min-height: 400px;
    .login-btn{
        width: 100%;
    }
    .btn-wrap{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 22px;
    }
    .label{
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #000000;
    }
    .account{
        margin: 14px 0;
        text-align: center;
    }
    .alert-card {
        position: relative;
        font-size: 14px;
        border-radius: 2px;
        padding: 15px 17px;
        margin-bottom: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: rgba(0,0,0,0.65);
        i{
            margin-right: 8px;
        }
        &.status-1 {
            background: #f6ffed;
            border: 1px solid #b7eb8f;
            i{
                color: #67C23A;
            }
        }
        &.status-2 {
            background: #fff1f0;
            border: 1px solid #ffa39e;
            i{
                color: #F56C6C;
            }
        }
        .close-btn {
            position: absolute;
            top: 7px;
            right: 0px;
            cursor: pointer;
            i{
                color: #999 !important;
            }
        }
    }
}
</style>
