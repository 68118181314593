<template>
    <div class="pwd-input">
        <el-input v-model="inputVal"
            :placeholder="placeholder"
            :clearable="clearable"
            show-password
            @input="handleInput">
        </el-input>
        <transition name="el-fade-in">
            <div class="level-list" v-show="inputVal">
                <span v-for="n in 5"
                    :key="n"
                    :style="`background: ${level >= n ? colors[level] : ''}; transition: background .3s ease;`">
                </span>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    props: {
        value: String,
        placeholder: String,
        clearable: Boolean,
        colors: {
            type: Array,
            default: () => ['', '#FF4F4F', 'rgba(230, 162, 60, .8)', 'rgba(230, 162, 60, 1)', 'rgba(80, 196, 27, .9)', 'rgba(80, 196, 27, 1)']
        }
    },
    data () {
        return {
            inputVal: this.value
        }
    },
    computed: {
        level () {
            return this.checkStrong(this.inputVal) || 0
        }
    },
    methods: {
        // 实时更新值
        handleInput () {
            this.$emit('input', this.inputVal)
        },
        // 返回密码的强度级别
        checkStrong (sPW) {
            // if (sPW.length < 8 || sPW.length > 20) {
            //     return 0 // 密码太短或太长
            // }
            let Modes = 0
            for (let i = 0; i < sPW.length; i++) {
                // 测试每一个字符的类别并统计一共有多少种模式.
                Modes |= this.charMode(sPW.charCodeAt(i))
            }
            if (sPW.length > 8) {
                Modes |= 16
            }
            return this.bitTotal(Modes)
        },
        // 判断字符类型
        charMode (iN) {
            // 数字
            if (iN >= 48 && iN <= 57) {
                return 1
            }
            // 大写字母
            if (iN >= 65 && iN <= 90) {
                return 2
            }
            // 小写
            if (iN >= 97 && iN <= 122) {
                return 4
            }
            return 8 // 特殊字符
        },
        // 统计字符类型
        bitTotal (num) {
            let modes = 0
            for (let i = 0; i < 5; i++) {
                if (num & 1) modes++
                num >>>= 1
            }
            return modes
        }
    }
}
</script>

<style lang="scss" scoped>
    .pwd-input{
        position: relative;
        .level-list{
            position: absolute;
            bottom: -14px;
            left: 0;
            right: 0;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            height: 14px;
            span{
                display: inline-block;
                width: 32px;
                height: 6px;
                background: #efefef;
                border-radius: 1px;
                &:not(:last-of-type) {
                    margin-right: 5px;
                }
            }
        }
    }
</style>
